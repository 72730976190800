const TRACKING_EVENT = Object.freeze({
  pageView: 'Page View',
  viewEventDetail: 'View Event Detail',
  viewEventListings: 'View Event Listings',
  viewEventSubmissionStep: 'View Event Submission Step',
  viewEditorial: 'View Editorial',
  loginAttempt: 'Login Attempt',
  loginSuccess: 'Login Success',
  completeRegistration: 'Complete Registration',
  viewArtistPage: 'View Artist Page',
  viewClubPage: 'View Club Page',
  viewPromoterPage: 'View Promoter Page',
  viewRegionPage: 'View Region Page',
  viewLabelPage: 'View Label Page',
  viewHomepage: 'View Homepage',
  viewAboutPage: 'View About Page',
  viewProPage: 'View Pro Page',
  viewMusicSection: 'View Music Section',
  viewMagazineSection: 'View Magazine Section',
  revealPassword: 'Reveal Password',
  searchResultClicked: 'Search Result Clicked',
  helpscoutBeaconClick: 'Helpscout Beacon Clicked',
  viewFestivalsHomepage: 'View Festivals Homepage',
  viewFollowingPage: 'View Following Page',
  viewInboxPage: 'View Inbox Page',
  clickedLogout: 'Clicked Log out',
  openSearchModal: 'Open search modal',
  viewInvoicePage: 'View Invoice Page',
  downloadInvoice: 'Download Invoice',
  viewTicketsPage: 'View Tickets Page',
  openCreateTicketForm: 'Open Create Ticket Form',
  addTicketsFromRAProHomepageClicked:
    'Add Tickets From RA Pro Homepage Clicked',
  saveAndCreateAnotherTicketClicked: 'Save And Create Another Ticket Clicked',
  viewSourceResults: 'View Source Results',
  clickSocialMediaLink: 'Click Social Media Link',
  savedBankingDetails: 'Saved Banking Details',
  viewBankingReminder: 'View Banking Reminder',
  subscribedToTicketNotifications: 'Subscribed To Ticket Notifications',
  unsubscribedFromTicketNotifications: 'Unsubscribed From Ticket Notifications',
  promoterCreationStarted: 'Promoter Creation Started',
  promoterCreationSucceeded: 'Promoter Creation Succeeded',
  cookiesDecisionMade: 'GDPR Cookies decision made',
  scrolledEditorial: 'Scrolled Editorial',
  sourceArtistSidebarOpened: 'Source Artist Sidebar Opened',
  sourceArtistSidebarClicked: 'Source Artist Sidebar Clicked',
  lightboxCallToActionClicked: 'Lightbox Call To Action Clicked',
  sourceSavedArtistUpdate: 'Source Saved Artist Updated',
  sourceSavedArtistListUpdate: 'Source Saved Artist List Updated',
  viewSourceSavedArtists: 'View Source Saved Artists',
  viewSourceSavedArtistLists: 'View Source Saved Artist Lists',
  viewSourceSavedArtistList: 'View Source Saved Artist List',
  viewSourceSavedArtistListPlanner: 'View Source Saved Artist List Planner',
  clickedEventInEventListing: 'Clicked Event In Event Listing',
  popularCarouselEventClicked: 'Popular Carousel Event Clicked',
  viewUserSetting: 'View User Setting',
  attemptCreateBump: 'Attempt Start Bump',
  createdBump: 'Created Bump',
  attemptEditBump: 'Attempt Edit Bump',
  editedBump: 'Edited Bump',
  attemptRegistration: 'Attempt Registration',
  savedEventsPageViewed: 'Saved Events Page Viewed',
  fourthNavLinkClicked: 'Fourth Nav Link Clicked',
  navMenuItemClicked: 'Nav Menu Item Clicked',
  eventSaved: 'Event Saved',
  shownPromotedEventListing: 'Shown Promoted Event Listing',
  clickedPromotedEventListing: 'Clicked Promoted Event Listing ',
  eventListingPageLoad: 'Event Listing Page Load',
  performanceTracking: 'Performance Tracking',
  bcorpLogoClicked: 'BCorp Logo Clicked',
  magicLinkSent: 'Magic Link Sent',
})

type TrackingEventType = typeof TRACKING_EVENT[keyof typeof TRACKING_EVENT]
export default TRACKING_EVENT
export type { TrackingEventType }
